@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* Navbar */
/* Add these styles to your CSS file */

nav {
  display: flex;
  flex-direction: column;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  padding: 15px;
  color: #fff;
}

.nav-link {
  position: relative;
  border: #fff;
}

.sub-links {
  @apply bg-primary;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: #000;
  list-style: none;
  cursor: pointer;
}

.sub-links-li:hover {
  @apply bg-quaternary text-black;
}

.nested-links {
  @apply bg-primary;
  display: none;
  position: absolute;
  top: 0%;
  left: 95%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: #000;
  list-style: none;
  cursor: pointer;
}

.sub-links:focus
 .nested-links {
  display:grid;
  @apply bg-black text-white;
}

.nested-links-li:hover {
  @apply bg-white text-black;
}

.nav-link:hover .sub-links {
  display: block;
}
/* Add additional styles as needed */
li {
  text-align: left;
}

.box {
  @apply w-40 h-40 bg-quaternary rounded-lg border-2;
}

h1,
h2 {
  color: #00069c;
  font-weight: bold;
}

li,
p {
  @apply text-lg text-justify;
}


.footer span {
  font-weight: 700;
}

.footerMargin {
  margin-top: 5rem;
}

.homeBox {
  justify-content: center;
  text-align: center;
  align-self: center;
}

.box:hover {
  @apply bg-quaternary;
}

.transition-bg {
  transition: all 1.5s;
  duration: 1500ms;
}

.nav {
  align-items: center;
  position: relative;
}

.nav .hamburger-icon {
  display: none;
}

.nav-banner {
  height: 18rem;
}

@media (max-width: 1450px) {
  .nav-banner {
    height: 10rem;
  }
}

@media (max-width: 1260px) {
  .nav-banner {
    height: 10rem;
  }
}

@media (max-width: 1190px) {
  .nav-banner {
    height: 10rem;
  }
}

@media (max-width: 950px) {
  .nav-banner {
    height: 8rem;
  }
}

@media (max-width: 785px) {
  .nav-banner {
    height: 6rem;
  }

  .nav .nav-links {
    display: none;
  }

  .nav.show-menu .nav-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .nav.show-menu .sub-links {
    width: 100%;
    text-align: center;
  }

  .nav .hamburger-icon {
    display: flex;
    cursor: pointer;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
}

@media (max-width: 600px) {
  .nav-banner {
    height: 5rem;
  }
}

@media (max-width: 490px) {
  .nav-banner {
    height: 3rem;
  }
}

@media (max-width: 394px) {
  .nav-banner img {
    display: none;
  }
  .nav-banner {
    background-image: url("./assets/final1.png");
    background-size: cover;
    height: 3rem;
    width: 100%;
    padding-bottom: 8rem;
  }
}

.li-margin li {
  margin-bottom: 1rem;
}
